import React from "react"
import 'antd/dist/antd.css';
import Page1 from "./landing/landing";


export default function Home() {
  return (
    <>
      <Page1 key="page1" />
    </>
  );
}
